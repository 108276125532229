var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.offers,"loading":_vm.loading,"loader-height":"2","sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortDesc,"options":_vm.options,"server-items-length":_vm.totalItems,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.company))])]}}],null,true)},[(item.extra_emails)?_c('div',[_c('div',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('from'))+": ")]),_vm._v(_vm._s(item.extra_emails.from || '--'))]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('booking'))+": ")]),_vm._v(_vm._s(item.extra_emails.booking || '--'))])]):_vm._e()])]}},{key:"item.proposal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['proposal'])+" "),_c('i',{staticClass:"text-body-2"},[_vm._v(_vm._s(item['currency']))]),_vm._v(" / "+_vm._s(item['company_price'])+" "),_c('i',{staticClass:"text-body-2"},[_vm._v(_vm._s(item['currency']))])]}},{key:"item.lead_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['lead_time'])+" "+_vm._s(_vm.leadTimeDays(item['lead_time']))+" ")]}},{key:"item.emissions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['emissions'] ? item['emissions'] : '--')+" ")]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['valid_to']))}})]}},{key:"item.transport_mode_shortcode",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-7",attrs:{"color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.TRANSPORT_ICONS[item['transport_mode_shortcode']])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")(item['transport_mode_shortcode'])))])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment)?_c('v-tooltip',{attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comment))])]):_vm._e(),(item['reopen_comment'])?_c('v-tooltip',{attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('div',[_c('div',{staticClass:"mb-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('reopenComment'))+":")])]),_vm._v(" "+_vm._s(item['reopen_comment'])+" ")])]):_vm._e()]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return _vm._l((item['attachments']),function(fileData){return _c('v-tooltip',{key:fileData['attachment_name'],attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":fileData['attachment_link'],"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-file")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(fileData['attachment_name']))])])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.STATUSES.BOOKED)?_c('v-btn',{staticClass:"justify-center text-subtitle-2",attrs:{"rounded":"","fab":"","x-small":"","depressed":"","color":"success","to":{name: 'booking-summary', query: {request_id: _vm.requestId, proposal_id: item.id}}}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-book-open-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('bookingSummaryOpen')))])])],1):_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.STATUS_COLORS[item.status],"size":"20"}},[_vm._v(_vm._s(_vm.STATUS_ICONS[item.status]))]),_c('span',[_vm._v(_vm._s(_vm._f("localize")(item.status)))])],1)]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.STATUSES.EXPIRED || item.status === _vm.STATUSES.NOT_BOOKED)?_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.status === _vm.STATUSES.EXPIRED)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.$refs.reopenDialog.openDialog(); _vm.reopenId = item.id}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('reopen')))])],1):_vm._e(),(item.status === _vm.STATUSES.EXPIRED || item.status === _vm.STATUSES.NOT_BOOKED)?_c('v-list-item',{attrs:{"link":"","to":{name: 'booking', query: {offer_id: item.id, request_id: _vm.requestId, mode: item['transport_mode_shortcode']}}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('book')))])],1):_vm._e()],1)],1):_vm._e()]}}])}),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.totalPages,"total-visible":7,"disabled":_vm.loading},on:{"input":function($event){_vm.offers = [];_vm.getOffers();}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('ReopenDialog',{ref:"reopenDialog",attrs:{"offerId":_vm.reopenId},on:{"updateOffers":_vm.getOffers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }