<template>
  <v-dialog
    v-model="dialog"
    width="500"
    overlay-color="black"
    :overlay-opacity="0.3"
    persistent
  >
    <v-card class="text-center px-6">
      <v-card-title class="text-h5 d-block pt-6 pb-6 px-0" style="word-break: normal;">{{ 'reopenQuote'|localize }}</v-card-title>
      <v-card-text class="text-body-1 pb-2 px-0">{{ 'reopenQuoteNote'|localize }}</v-card-text>
      <v-card-text class="text-body-1 text-left pt-4 pb-0 px-0">
        <v-form class="mb-5" ref="reopenForm" lazy-validation>
          <h6 class="text-subtitle-2 mb-2">{{'comment' | localize}} *</h6>
          <v-textarea
            rows="5"
            v-model="comment"
            background-color="white"
            dense
            outlined
            counter="1200"
            :rules="[validationRules.required, commentsMaxLengthRule]"
          />
          <h6 class="text-subtitle-2 mb-2">{{'quoteValidTo' | localize}} *</h6>
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="validTo"
                v-model="valid_to_menu"
                :close-on-content-click="false"
                :return-value.sync="valid_to_menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="white"
                    :placeholder="'selectDate' | localize"
                    prepend-inner-icon="mdi-calendar"
                    v-model="valid_to_date"
                    readonly
                    dense
                    outlined
                    v-on="on"
                    :rules="[validationRules.required]"
                    clearable
                    @click:clear="valid_to_date = ''"
                  />
                </template>
                <v-date-picker
                  v-model="valid_to_date"
                  no-title
                  scrollable
                  :min="today"
                  @input="$refs.validTo.save(valid_to_date)"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-select
                background-color="white"
                v-model="valid_to_time"
                :items="methods.intervals()"
                item-value="key"
                item-text="value"
                dense
                outlined
                :placeholder="'selectTime' | localize"
                prepend-inner-icon="mdi-clock"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-0">
        <v-row>
          <v-col>
            <v-btn color="primary" block outlined rounded :disabled="loading" @click="closeDialog">{{ 'back'|localize }}</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" block depressed rounded :loading="loading" @click="reopenOffer">{{ 'reopen'|localize }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import localize from '../filters/localize';
  import validationRules from '../helpers/validationRules';
  import methods from '../helpers/methods';
  import api from '../api/api';

  export default {
    name: 'ReopenDialog',

    props: ['offerId'],

    data() {
      return {
        validationRules,
        methods,
        requestId: this.$router.currentRoute.query.request_id,
        dialog: false,
        loading: false,
        comment: '',
        valid_to_menu: false,
        valid_to_date: '',
        valid_to_time: '',
      }
    },

    computed: {
      commentsMaxLengthRule() {
        return v => v.length <= 1200 || localize('max_1200_characters');
      },
      today() {
        return new Date().toISOString().slice(0, 10);
      },
    },

    methods: {
      openDialog() {
        this.dialog = true;
      },
      resetForm() {
        this.comment = '';
        this.valid_to_date = '';
        this.valid_to_time = '';
        this.$refs.reopenForm.resetValidation();
      },
      closeDialog() {
        this.dialog = false;
        this.resetForm();
      },
      queryData() {
        let queryData = {};
        queryData.comment = this.comment;
        queryData.valid_to_date = this.valid_to_date;
        this.valid_to_time && (queryData.valid_to_time = this.valid_to_time);
        return queryData;
      },
      async reopenOffer() {
        if (this.$refs.reopenForm.validate()) {
          try {
            this.loading = true;
            await api.reopenOffer(this.offerId, this.queryData());
            this.$emit('updateOffers');
            this.loading = false;
            this.closeDialog();
          } catch (e) {
            this.loading = false;
          }
        }
      }
    }
  };
</script>
